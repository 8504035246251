import React from "react";
import { LayoutGeneral } from "src/layout/LayoutGeneral";
import { RollbackOutlined } from "@ant-design/icons";
import { Link } from "gatsby";
import { Layout } from "antd";
import Faunia1 from "src/components/referencias/images/cubiertas/faunia-madrid/climateCoating-thermoActive-carpa1.jpg";
import Faunia2 from "src/components/referencias/images/cubiertas/faunia-madrid/climateCoating-thermoActive-carpa2.jpg";
import Faunia3 from "src/components/referencias/images/cubiertas/faunia-madrid/climateCoating-thermoActive-carpa3.jpg";

import { ThermoActiveImgAndLinks } from "src/components/referencias/cubiertas/ThermoActive";

import { FacebookOutlined, InstagramOutlined } from "@ant-design/icons";

import { Image } from "antd";
import { List, Card } from "antd";
const { Header, Content } = Layout;

const data = [
  {
    src: Faunia1,
  },
  {
    src: Faunia2,
  },
  {
    src: Faunia3,
  },
];

const FauniaMadrid = (props) => (
  <LayoutGeneral
    {...{
      ...props,
      showPageTitle: false,
      pageTitle: "ThermoActive: Carpa en Faunia Madrid",
      pageName: "Referencia ThermoActive",
      pageDescription:
        "ClimateCoating ThermoActive: Usado revistiendo una carpa en Faunia - Madrid",
    }}
  >
    <Content className="thermoActive referencia">
      <div className="linkBack referencia">
        <Link to="/referencias/cubiertas/">
          <RollbackOutlined />{" "}
          <span className="colored linkText">Ir a Referencias Cubiertas</span>
        </Link>
      </div>

      <div className="marginTop50">
        <ThermoActiveImgAndLinks />
        <h1 className="pageTitle referencia">
          <b className="thermoActive">ClimateCoating ThermoActive: </b>
          <span className="secondText">
            Aplicación sobre sobre Carpa y cubierta en Faunia
          </span>
        </h1>
        <p>
          Cómo puede ver en detalle en la página de
          <Link to={"/productos/thermoActive/"} className="grey bold">
            ThermoActive
          </Link>
          , además de sus características reflectantes y flexibilidad, es un
          producto extraordinariamente respetuoso con el medio ambiente.
        </p>
        <p>
          En un parque temático donde hay gran afluencia de gente y presencia de
          animales,{" "}
          <strong className="grey">
            contar con un producto sin disolventes y ecólogico
          </strong>
          , es fundamental para conseguir los resultados deseados.
        </p>
        <br />
        <br />
      </div>
      <div className="clearer"></div>
      <Image.PreviewGroup preview={{}}>
        <List
          grid={{ gutter: 16, column: 3 }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <Image src={item.src} />
            </List.Item>
          )}
        />
      </Image.PreviewGroup>
      <br />
      <br />
    </Content>
  </LayoutGeneral>
);

export default FauniaMadrid;
